import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "orgTable", "assignToggle", "unassignToggle", "checkbox", "assignmentForm", "tableBody", "noOfRecordsLoaded", "maxNoOfRecords", "backToTop" ];

    showAssigned(event) {
        this.toggle();
        this.loadTable(this.assignedUrl);
    }

    showUnassigned(event) {
        this.toggle();
        this.loadTable(this.unassignedUrl);
    }

    toggleCheckboxes(event) {
        $(this.checkboxTargets).prop("checked", $(event.target).prop('checked'));
    }

    scroll(event) {
        event.preventDefault();

        this.toggleBackToTop();

        if (this.atBottomOfPage && !this.scrolling && !this.allRecordsLoaded) {

            this.scrolling = true;

            if (this.wantAssigned()) {
                this.loadRows(this.assignedUrl)
            } else {
                this.loadRows(this.unassignedUrl)
            }
        }

    }

    assignOrgs(event) {
        event.preventDefault();

        if (this.verifyOrgSelected()) { this.postChangesTo(this.assignmentFormTarget) }
    }

    unassignOrgs(event) {
        event.preventDefault();

        if (this.verifyOrgSelected()) { this.postChangesTo(this.assignmentFormTarget) }

    }

    backToTop(event) {
        event.preventDefault();

        var distance = 0 - window.pageYOffset;
        var increments = distance/(500/16);
        function animateScroll() {
            window.scrollBy(0, increments);
            if (window.pageYOffset <= document.body.offsetTop) {
                clearInterval(runAnimation);
            }
        };
        // Loop the animation function
        var runAnimation = setInterval(animateScroll, 16);
    }

    get url() {
        return this.data.get("url");
    }

    get orgType() {
        return this.data.get("orgType");
    }

    get displayOrgType() {
        return this.data.get("displayOrgType");
    }

    get recordLimit() {
        return this.data.get("recordLimit");
    }

    get $parentContainer() {
        return $(this.orgTableTarget).parents(".tab-content").last();
    }

    get organizationsSelected() {
        return this.checkboxTargets.filter( ({ checked }) => checked === true )
    }

    get assignedUrl() {
        return `${this.url}&assignment_type=assigned`;
    }

    get unassignedUrl() {
        return `${this.url}&assignment_type=unassigned`;
    }

    get noOfRows() {
        const $tableBody = $(this.tableBodyTarget);
        return $tableBody.children().length;
    }

    get allRecordsLoaded() {
        return parseInt($(this.maxNoOfRecordsTarget).text()) === this.noOfRows;
    }

    get atBottomOfPage() {
        return ($(window).scrollTop() + $(window).height() > $(document).height() - 100)
    }

//    private methods

    wantAssigned() {
        return $(this.assignToggleTarget).hasClass('active');
    }

    loadTable(url) {
        const $target = $(this.orgTableTarget);
        const that = this;

        this.$parentContainer.block(blockUIOptions());

        $target.load(url, function () {
            that.$parentContainer.unblock();
        })
    }

    loadRows(url) {

        const $tableBody = $(this.tableBodyTarget);
        const $noOfRecordsLoaded = $(this.noOfRecordsLoadedTargets);
        const that = this;

        fetch(url+`&offset=${this.noOfRows}`)
            .then(response => response.text())
            .then(data => {
                $tableBody
                    .append(data);
                $noOfRecordsLoaded
                    .text(that.noOfRows);
                this.scrolling = false;
            })

    }

    toggle() {
        $(this.assignToggleTarget).toggleClass('active');
        $(this.unassignToggleTarget).toggleClass('active');
    }

    postChangesTo(form) {
        const formUrl = $(form).attr('action');
        const $target = $(this.orgTableTarget);
        const that = this;

        this.$parentContainer.block(blockUIOptions());

        $.post(formUrl, $(form).serialize())
            .done(function(html) {
                $target.html(html);
                that.$parentContainer.unblock();
            })
    }

    verifyOrgSelected() {
        if (!$(this.checkboxTargets).is(':checked')) {
            alert(`You must select at least one ${this.displayOrgType.toLowerCase()}`);

            return false;
        } else {
            return true;
        }
    }

    // https://heathertovey.com/blog/floating-back-to-top-button/
    toggleBackToTop() {
        const amountScrolled = 250;

        if ( window.pageYOffset > amountScrolled ) {
            this.backToTopTarget.classList.add('show');
        } else {
            this.backToTopTarget.className = 'back-to-top';
        }
    }
}
