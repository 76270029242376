
require('block-ui');

import { Controller } from "stimulus"

export default class extends Controller {

    showTab(event) {
        const $tabTarget = $($(event.target).attr("href"));

        $tabTarget.block(blockUIOptions());

        $tabTarget.load(this.url, function () {
            $tabTarget.unblock();
        })
    }

    get url() {
        return this.data.get("url")
    }
}
