import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "content"];

    load(event) {
       event.preventDefault();

       // TODO figure out why show.bs.collapse is not firing...this is the workaroud
       if (($(event.target).attr('aria-expanded') == undefined && $(event.target).parent().attr('aria-expanded') == "false") ||
            $(event.target).attr('aria-expanded') == "false") {

           const $target = $(this.contentTarget);

           $target.block(blockUIOptions());

           $target.load(this.url, () => { $target.unblock() })
       }

    }

    get url() {
        return this.data.get("url")
    }

}